<template>
  <div>
    <section class="about">
      <div class="container">
        <div class="about_wrap">
          <div class="about_col">
            <h2 class="about_title">
              {{ $t("about_our_company") }}
            </h2>
            <p class="about_text">
              <span class="emoji">👋</span>
              {{ $t("company_description") }}
            </p>
            <p class="about_text">
              <span class="emoji">👉</span>
              {{ $t("company_description_2") }}
            </p>
            <p class="about_text">
              <span class="emoji">✊</span>
              {{ $t("company_description_3") }}
            </p>
          </div>
          <div class="about_col">
            <img class="about_img" src="@/assets/images/landing/flag.png" alt="">
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="team">
      <div class="container">
        <h2 class="team_title">
          Команда
        </h2>
        <div class="team_wrap">
          <div class="team_member">
            <img src="@/assets/images/landing/team-member.png" alt="member">
            <div class="name">
              Апсалямов Азиз
            </div>
            <div class="position">
              Отвечает за слова
            </div>
          </div>
          <div class="team_member">
            <img src="@/assets/images/landing/team-member.png" alt="member">
            <div class="name">
              Шауберт Илья
            </div>
            <div class="position">
              Развивает проект
            </div>
          </div>
          <div class="team_member">
            <img src="@/assets/images/landing/team-member.png" alt="member">
            <div class="name">
              Мурашев Дастан
            </div>
            <div class="position">
              Следит за работой сайта
            </div>
          </div>
          <div class="team_member">
            <img src="@/assets/images/landing/team-member.png" alt="member">
            <div class="name">
              Жакытай Дарига
            </div>
            <div class="position">
              Занимается документами
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: "About",
  mounted() {
    // this.initSlider();
  },
  methods: {
    initSlider() {
      const slider = document.querySelector('.team_wrap');
      let isDown = false;
      let startX;
      let scrollLeft;
      slider.addEventListener('mousedown', (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 38px;
  font-weight: 700;
  text-transform: uppercase;
}

.about {
  padding: 22px 0 70px;
  color: #0B1F35;

  &_title {
    margin-bottom: 10px;
  }

  &_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 28px;
  }

  &_text {
    font-size: 20px;
    position: relative;
    margin-bottom: 32px;
    display: flex;
    align-items: flex-start;
    gap: 6px;

    .emoji {
      font-size: 24px;
      line-height: 1em;
    }
  }

  &_img {
    border-radius: 16px;
    max-width: 100%;
    width: 554px;
    display: block;
    margin-left: auto;
  }
}

.team {
  background: #007BFC;
  padding: 50px 0 62px;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;

  &_title {
    margin-bottom: 20px;
  }

  &_wrap {
    display: flex;
    align-items: center;
    gap: 22px;
    overflow-x: scroll;
    cursor: grab;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }

    &.active {
      cursor: grabbing;
    }
  }

  &_member {
    img {
      margin-bottom: 6px;
    }

    .name {
      font-size: 22px;
      font-weight: 600;
    }

    .position {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 992px) {
  .about {
    &_wrap {
      display: block;
    }

    &_img {
      margin: auto;
    }
  }
}

@media screen and (max-width: 720px) {
  h2 {
    font-size: 24px;
  }
  .about_text {
    font-size: 18px;
  }
  .team {
    .container {
      padding: 0;
    }

    &_wrap {
      padding: 0 15px;
    }

    &_member {
      img {
        width: 200px;
      }

      .name {
        font-size: 16px;
      }

      .position {
        font-size: 12px;
      }
    }
  }
}
</style>
